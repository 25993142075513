import React from "react";
import { Col, Container, Row, Nav, Tab } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import "animate.css";
import TrackVisibility from "react-on-screen";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.png";
import projImg5 from "../assets/img/project-img5.png";
import colorSharp2 from "../assets/img/color-sharp2.png";

export default function Projects() {
  const projects = [
    {
      title: "Assasey Realstate Marketplace Startup",
      description: "Design, Development & Deployment",
      imageURL: projImg1,
      linkURL: "https://assasey.com",
    },
    {
      title: "Corporate Web Application",
      description: "Development & Deployment",
      imageURL: projImg2,
      linkURL: "http://newwestfinancial.com/",
    },
    {
      title: "Amanahfy Donation Management System",
      description: "Design & Development",
      imageURL: projImg3,
      linkURL: "https://www.amanahfy.com/",
    },
    {
      title: "Crowdfunding Application",
      description: "Design, Development and Deployment",
      imageURL: projImg5,
      linkURL: "https://github.com/abdullahbinahmed/Crowd-Funding",
    },
    {
      title: "Corporate Web Application",
      description: "Design, Development & Deployment",
      imageURL: projImg4,
      linkURL: "#",
    },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imageURL: projImg3,
    //   linkURL: "https://assasey.com",
    // },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col md={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={`project ${isVisible ? "animate__animated animate__swing" : ""}`}
                >
                  <h2>Projects</h2>
                  <p>Successful projects executed.</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">Tab 1</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Tab 2</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Tab 3</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                      {/*<Tab.Pane eventKey="second">Loren Ipsum</Tab.Pane>*/}
                      {/*<Tab.Pane eventKey="third">Loren Ipsum</Tab.Pane>*/}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img
        className="background-image-right"
        src={colorSharp2}
        alt="Right Background"
      />
    </section>
  );
}
