import { Col } from "react-bootstrap";
import PropTypes from "prop-types";
export default function ProjectCard({ title, description, imageURL, linkURL }) {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbxlink">
        <a href={linkURL} target="_blank" rel="noopener noreferrer">
          <div className="proj-imgbx">
            <img src={imageURL} alt={title} />

            <div className="proj-txtx">
              <h4>{title}</h4>
              <span>{description}</span>
            </div>
          </div>
        </a>
      </div>
    </Col>
  );
}
ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageURL: PropTypes.string.isRequired,
  linkURL: PropTypes.string.isRequired,
};
