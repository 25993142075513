import { Container, Row } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import headerImg from "../assets/img/header-img.svg";
import { useState, useEffect } from "react";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { HashLink } from "react-router-hash-link";

export default function Banner() {
  const [loopNumber, setLoopNumber] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const toRotate = [
    "Full Stack Developer",
    "Backend Developer",
    "MERN Stack Developer",
  ];
  const [taglineText, setTaglineText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [taglineText]);

  const tick = () => {
    let index = loopNumber % toRotate.length;
    let fullText = toRotate[index];
    let updatedText = isDeleting
      ? fullText.substring(0, taglineText.length - 1)
      : fullText.substring(0, taglineText.length + 1);

    setTaglineText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNumber(loopNumber + 1);
      setDelta(500);
    }
  };
  return (
    <section className="banner" id="home">
      <Container>
        <Row>
          <div className="col-12 col-md-6 col-xl-7">
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <span className="tagline">Welcome to my Portfolio</span>
                  <h1>
                    {`Hi! I'm Abdullah `}
                    <br />
                    <span className="wrap">{taglineText}</span>
                  </h1>
                  <p>
                    I am a MERN Stack Certified developer from Coursera with 4
                    years of experience developing, designing and deploying
                    websites and mobile applications as a freelancer. I have
                    also worked as a Software Engineer for a Fintech Company for
                    1 year as a backend developer in NodeJS, C++ and Python.
                  </p>
                  <HashLink to="#connect" className="no-underline">
                    <button>
                      Let's connect
                      <ArrowRightCircle size={25} />
                    </button>
                  </HashLink>
                </div>
              )}
            </TrackVisibility>
          </div>
          <div className="col-12 col-md-5 col-xl-5">
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                >
                  <img src={headerImg} alt="Header Img" />
                </div>
              )}
            </TrackVisibility>
          </div>
        </Row>
      </Container>
    </section>
  );
}
