import { Col, Container, Row } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import navIcon4 from "../assets/img/nav-icon4.svg";
export default function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col sm={6} className="text-center">
            <div className="social-icon">
              <a
                href="https://www.linkedin.com/in/smabdullahahmed"
                target="_blank"
              >
                <img src={navIcon1} alt="Navigation Icon 1" />
              </a>
              <a
                href="https://www.upwork.com/freelancers/smabdullahahmed"
                target="_blank"
              >
                <img src={navIcon4} alt="" />
              </a>
              {/*<a href="">*/}
              {/*  <img src={navIcon3} alt="Navigation Icon 3" />*/}
              {/*</a>*/}
            </div>
            <p>Copyright 2024. All Rights Reserved by AnAIT</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
